<template>
  <div class="warelist" :class="`warelist--${props.type ?? 'standard'}`" ref="wareList">
    <ClientOnly>
      <div v-if="props.withSorting" class="flex flex-wrap justify-between items-center gap-x-24 gap-y-6 py-2 lg:py-8">
        <div class="warelist__sort" :class="{ uppercase: !sortMenuOpen }">
          <button v-for="item in sortOptions" @click="sort(item.key)" class="warelist__sort__item" :class="{ 'warelist__sort__item--selected': currentSort === item.key }">
            <span v-if="item.key === currentSort" class="lg:hidden">&gt;</span>&nbsp;{{ $t(item.label) }}
          </button>
        </div>
        <AtomsGlobalLink class="mb-4 lg:mb-0 text-sm" :to="appConfig.definedPageUrls.wareOrder" target="_blank">{{ $t("Jak řadíme zboží ve výchozím zobrazení?") }}&nbsp;
          <AtomsImagesIcon icon="sipka" size="xs" class="ml-1" />
        </AtomsGlobalLink>
      </div>
    </ClientOnly>
    <div class="warelist__items">
      <template v-if="props.type === 'slider'">
        <carousel :breakpoints="carouselBreakpoints" :itemsToShow="1" :itemsToScroll="1">
          <slide v-for="(item, index) in props.items" :key="index">
            <MoleculesBlocksWareListItem :item="item" :index="getIndex(index)" :ware-id="item.id" :link-target="props.itemsLinkTarget" :name="item.name" :url="item.page?.url"
            :image-id="item.image?.id" :stored="item.stored" :min-price="item.price.minPrice?.price" :max-price="item.price.maxPrice?.price" :unit="item.unit" :ware-delivery-text="item.deliveryText" :ware-min-next-delivery="item.minNextDelivery" :variant-delivery-text="item.usedVariant?.deliveryText" :variant-delivery-period="item.usedVariant?.deliveryPeriod" :variant-stored-on-own-store="item.usedVariant?.storedOnOwnStore" :variant-available-count="item.usedVariant?.availableCount" :labels="item.properties?.filter(item => item.id === appConfig.parameterIds.label)" :list-id="props.groupId" :list-name="props.listName" @like-list-removed="likeListRemoved" />
          </slide>
          <template #addons>
            <Navigation v-if="props.withSliderNavigation" />
            <Pagination v-if="props.withSliderPagination && props.items.length > 1" />
          </template>
        </carousel>
      </template>
      <template v-else>
        <MoleculesBlocksWareListItem v-for="(item, index) in props.items" :item="item" :index="getIndex(index)" :ware-id="item.id" :link-target="props.itemsLinkTarget" :name="item.name" :url="item.page?.url" :image-id="item.image?.id" :stored="item.stored" :min-price="item.price?.minPrice?.price" :max-price="item.price?.maxPrice?.price" :unit="item.unit" :ware-delivery-text="item.deliveryText" :ware-min-next-delivery="item.minNextDelivery" :variant-delivery-text="item.usedVariant?.deliveryText" :variant-delivery-period="item.usedVariant?.deliveryPeriod" :variant-stored-on-own-store="item.usedVariant?.storedOnOwnStore" :variant-available-count="item.usedVariant?.availableCount" :labels="item.properties?.filter(item => item.id === appConfig.parameterIds.label)" :list-id="props.groupId" :list-name="props.listName" @like-list-removed="likeListRemoved" />
      </template>
    </div>
    <OrganismsListsPaging :key="props.page" v-if="props.pageSize" class="mt-6" :shown-items="shownItems" :total-pages="totalPages" :current-page="props.page"
    :total-items="props.totalItems" :auto-scroll-element="wareList"></OrganismsListsPaging>
  </div>
</template>
<script setup>

import { useAppConfig } from "nuxt/app";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

const props = defineProps({
  type: String,
  items: Object,
  page: Number,
  pageSize: Number,
  totalItems: Number,
  withSorting: Boolean,
  withSliderNavigation: Boolean,
  withSliderPagination: Boolean,
  itemsLinkTarget: String,
  groupId: Number,
  listName: String,
  filter: String
});

const appConfig = useAppConfig();
const shopRouting = useShopRouting();
const route = useRoute();
const analytics = useAnalytics();

const wareList = ref(null);

const shownItems = computed(() => Math.min(props.page * props.pageSize, props.totalItems));
const totalPages = computed(() => props.pageSize > 0 ? Math.ceil(props.totalItems / props.pageSize) : 0);

const getIndex = index => {
  const result = index + ((props.page ?? 1) - 1) * (props.pageSize ?? 1);
  return result;
}

const emit = defineEmits(['likeListRemoved']);
const likeListRemoved = () => emit('likeListRemoved');

watch(() => props.items, () => {

  if (props.listName) {

    const data = props.items.map(item => analytics.getItemDetails(item));

    // call view_item_list separately in blocks of approx. 10 items, otherwise the payload will be too large in some cases

    for (let i = 0; i < Math.ceil(data.length / 10); i++) {
      analytics.pushEvent('view_item_list', {
        ecommerce: {
          item_list_id: props.groupId,
          item_list_name: props.listName,
          item_list_page: props.page,
          item_list_filter: props.filter,
          items: data.slice(i * 10, (i * 10) + 10)
        }
      });
    }
  }
}, { immediate: true });

const carouselBreakpoints = {
  600: {
    itemsToShow: 2,
    snapAlign: "left",
  },
  1280: {
    itemsToShow: 3,
    snapAlign: "left",
  },
};

const sortOptions = [
  { key: "default", label: "Nejprodávanější" },
  { key: "newest", label: "Nejnovější" },
  { key: "cheapest", label: "Nejlevnější" },
  { key: "most-expensive", label: "Nejdražší" },
];

const sortMenuOpen = ref(false);
const currentSort = ref("default");

watch(route, async () => {

  const newSort = shopRouting.parseHash().find(q => q.key === 'sort')?.value[0];

  if (newSort && currentSort.value != newSort) {
    currentSort.value = newSort;
  }

}, { flush: 'pre', immediate: route.hash, deep: true });

const sort = (key) => {
  currentSort.value = key;

  // reset page to 1
  const modifiedHash = shopRouting.stringifyHash(shopRouting.updateValueInHash('p', '', false, true));

  // update sorting value in hash
  const hashObj = shopRouting.updateValueInHash("sort", key, false, true, modifiedHash);

  // navigate to the modified hash
  shopRouting.pushHash(hashObj);

};

</script>
<style lang="postcss">
.warelist {
  &__sort {
    @apply flex flex-wrap sm:flex-nowrap flex-1 justify-between gap-1 p-2 lg:p-0 border-b border-b-secondary;

    &__item {
      @apply block relative uppercase w-full p-1 lg:p-4 sm:w-auto;

      &--selected {
        @apply font-bold;

        &::after {
          @apply lg:content-[''] absolute left-0 right-0 top-[3.25rem] h-2 rounded-full bg-secondary;
        }
      }
    }
  }

  &--standard {
    & .warelist__items {
      @apply grid grid-cols-2 xl:grid-cols-6 gap-1;
    }
  }

  &--slider {
    & .warelist__items {
      @apply -mx-4;
    }
  }

  &--search {
    & .warelist__items {
      @apply block;
    }
  }
}
</style>
