<template>
    <AtomsGlobalLink class="warelist__item" :to="props.url" :target="props.linkTarget" @click="selectItem">
        <div class="warelist__item__labels">
            <span class="warelist__item__liked">
                <AtomsImagesIcon icon="oblibene" size="xl" :class="['block hover:scale-150 transition-all', { 'opacity-30': !isLiked, 'text-conversion': isLiked }]" :title="isLiked ? $t('Odebrat z oblíbených') : $t('Přidat do oblíbených')" @click.prevent="likedToggle" />
            </span>
            <div v-for="item in props.labels?.flatMap(q => q.values)" class="warelist__item__label" :class="`badge--${item.lValue}`">{{ item.value }}</div>
        </div>
        <MoleculesGlobalWareImage v-if="props.imageId" class="warelist__item__image" :ware-name="props.name" :image-id="props.imageId" suffix="pls" />
        <div class="warelist__item__info">
            <h3 class="warelist__item__name">{{ props.name }}</h3>
            <div class="warelist__item__availability" :class="{ 'warelist__item__availability--stored': availabilityInfo, 'warelist__item__availability--not-stored': !availabilityInfo }">{{ availabilityInfo || 'Vyprodáno' }}</div>
            <div class="warelist__item__price"><strong>{{ priceStr }}</strong> / {{ props.unit }}</div>
        </div>
    </AtomsGlobalLink>
</template>
<script setup>

const props = defineProps({
    item: Object,
    index: Number,
    wareId: Number,
    name: String,
    url: String,
    imageId: Number,
    minPrice: Number,
    maxPrice: Number,
    unit: String,
    labels: Array,
    linkTarget: String,
    listId: Number,
    listName: String,
    wareDeliveryText: String,
    wareMinNextDelivery: String,
    variantDeliveryText: String,
    variantDeliveryPeriod: Number,
    variantStoredOnOwnStore: Boolean,
    variantAvailableCount: Number,
});

const locale = useLocale();
const i18n = useI18n();
const appConfig = useAppConfig();
const analytics = useAnalytics();
const availability = useAvailability();

const priceStr = computed(() => (props.maxPrice > props.minPrice ? (i18n.t('od') + ' ') : '') + locale.formatPrice(props.minPrice));

const availabilityInfo = computed(() => availability.getVariantDispatchInfo(
    props.wareDeliveryText,
    props.variantDeliveryText,
    props.variantStoredOnOwnStore,
    props.variantAvailableCount,
    props.variantDeliveryPeriod,
    props.wareMinNextDelivery,
    appConfig.deadlineHour,
    availability.getFreeDays()
).text);

const liked = useLikedWares();
const isLiked = computed(() => liked.isLiked(props.wareId).value);

const likedToggle = () => {
    liked.toggle(props.wareId);
    if (!isLiked.value) {
        emit('likeListRemoved');
    }
}

const emit = defineEmits(['likeListRemoved']);

const selectItem = () => {
    analytics.pushEvent('select_item', {
        ecommerce: {
            item_list_id: props.listId,
            item_list_name: props.listName,
            index: props.index,
            items: [analytics.getItemDetails(props.item)]
        }
    })
}

</script>
<style lang="postcss">
.warelist__item {

    &__labels {
        @apply absolute top-5 right-5 z-10 space-y-2 text-sm uppercase font-bold;
    }

    &__label {
        @apply px-4 py-1 bg-conversion text-white;
    }

    &__image {
        @apply mx-auto min-w-0;
    }

    &__info {
        @apply flex flex-col flex-1;
    }

    &__name {
        @apply my-4 text-xs;
    }

    &__availability {
        @apply mt-auto mb-4;

        &--stored {
            @apply text-stored;
        }

        &--not-stored {
            @apply text-gray;
        }
    }

    &__price {
        @apply text-conversion;
    }
}

.warelist--standard {
    & .warelist__item {
        @apply flex flex-col px-3 py-8 lg:px-6 lg:py-10 relative text-center hover:shadow-around ease-in-out duration-200;

        &__info {
            @apply mt-auto;
        }

        &__availability {
            @apply mt-auto;
        }

        &__liked {
            @apply flex justify-end;
        }
    }
}

.warelist--slider {
    & .warelist__item {
        @apply @container w-full flex flex-wrap gap-6 items-center m-4 p-6 border border-silver text-left;

        &__labels {
            @apply absolute top-5 left-5 right-auto z-10 space-y-2 text-sm uppercase font-bold;
        }

        &__info {
            @apply w-full flex-none @2xs:flex-1;
        }

        &__name {
            @apply mt-0 text-xl text-gray;
        }

        &__availability {
            @apply my-1;
        }
    }
}

.warelist--search {
    & .warelist__item {
        @apply relative flex items-center gap-8 mb-6;

        &__labels {
            position: relative;
            order: 2;
            align-self: flex-end;
            top: -0.5rem;
            right: 0;
        }

        &__info {
            @apply block;
        }

        &__name,
        &__availability {
            @apply block xl:float-left xl:w-2/3;
        }

        &__name {
            @apply text-gray text-lg mt-0 mb-1;
        }

        &__availability {
            @apply text-sm mb-1;
        }

        &__price {
            @apply block xl:float-right xl:w-1/4 xl:min-w-[150px] xl:text-right text-lg text-primary;
        }

        &__image {
            width: 30%;
            max-width: 120px;
        }
    }
}
</style>