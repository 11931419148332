<template>
    <Image :alt="props.wareName" :src="url" :class="props.class" />
</template>
<script setup>
import Image from '../../atoms/images/image.vue';

const utils = useUtils();

const props = defineProps({
    imageId: Number,
    suffix: String,
    wareName: String,
    class: String
});

const appConfig = useAppConfig();

const url = computed(() => {

    const result = utils.getWareImageUrl(props.imageId, props.wareName, props.suffix);
    return result;

});

</script>