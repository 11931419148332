<template>
    <Head>
        <Link rel="prev" :href="config.SHOP_ZONE_AUTHORITY + getLink(props.currentPage - 1)" v-if="hasPrev" />
        <Link rel="next" :href="config.SHOP_ZONE_AUTHORITY + getLink(props.currentPage + 1)" v-if="hasNext" />
    </Head>
    <div class="text-center space-y-5" :class="props.class">
        <div v-if="props.totalPages > 1">
            <component>
                <AtomsGlobalLink :to="getLink(props.currentPage - 1)" v-if="hasPrev" class="mx-2 px-2" @click="pageChanged">
                    &lt;
                </AtomsGlobalLink>
                <AtomsGlobalLink :to="getLink(page.number)" v-for="page in pages" class="px-1 mx-1" :class="{ 'font-bold': page.isCurrent, 'text-lg': page.isCurrent }" @click="pageChanged">{{ page.number }}</AtomsGlobalLink>
                <AtomsGlobalLink :to="getLink(props.currentPage + 1)" v-if="hasNext" class="mx-2 px-2" @click="pageChanged">
                    &gt;
                </AtomsGlobalLink>
            </component>
        </div>
        <MoleculesButtonsButton :arrow-right="true" @click="append" v-if="hasNext">{{ props.labelMore ?? $t('Další produkty') }}</MoleculesButtonsButton>
        <div>
            <strong class="font-bold">{{ props.shownItems }}</strong> z <strong class="font-bold">{{ props.totalItems }}</strong> {{ props.label ?? $t('produktů') }}
        </div>
    </div>
</template>
<script setup>

const props = defineProps({
    currentPage: Number,
    totalPages: Number,
    shownItems: Number,
    totalItems: Number,
    class: String,
    label: String,
    labelMore: String,
    autoScrollElement: Object | undefined
});

const hasPrev = computed(() => props.currentPage > 1);
const hasNext = computed(() => props.currentPage < props.totalPages);

const config = useRuntimeConfig();
const shopRouting = useShopRouting();

const getLink = pageNumber => {

    const pathAndQuery = useRoute().fullPath.replace(useRoute().hash, '');
    const newHash = shopRouting.stringifyHash(shopRouting.updateValueInHash('p', pageNumber === 1 ? '' : pageNumber, pageNumber === 1, true));

    if (newHash === '#!') {
        return pathAndQuery;
    }

    return pathAndQuery + newHash;
};

const pages = computed(() => {
    let pages = [];

    let minNumber = Math.max(props.currentPage - 2, 1);
    let maxNumber = Math.min(props.currentPage + 2, props.totalPages);

    if (props.currentPage === 1 && props.totalPages > 3) {
        maxNumber++;
    }

    if (props.currentPage === props.totalPages && minNumber > 1) {
        minNumber--;
    }

    for (let i = minNumber; i <= maxNumber; i++) {
        pages.push({
            number: i,
            isCurrent: i === props.currentPage
        });
    }

    return pages;

});

const pageChanged = () => {
    if (props.autoScrollElement) {
        useUtils().scrollTo(props.autoScrollElement, 0);
    }
}

const append = () => {
    useRouter().push(getLink((props.currentPage + 1) + '-a'));
}

</script>