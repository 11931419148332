<template>
    <div>
        <ClientOnly>
            <AtomsGlobalOverlay :isVisible="props.isOpen" :vertical-align="props.verticalAlign" :customZIndex="2002" @click="close()" :title="$t('Kliknutím zavřete')">
                <div class="relative z-[2005] rounded-xl max-h-full shadow-around" :class="[{ 'overflow-auto': !props.overflow }, props.overflow, props.class]" title="">
                    <div class="bg-white h-full rounded-md">
                        <slot></slot>
                        <AtomsImagesIcon icon="zavreni" size="2xl" class="absolute right-5 top-5 cursor-pointer" @click="close()"></AtomsImagesIcon>
                    </div>
                </div>
            </AtomsGlobalOverlay>
        </ClientOnly>
    </div>
</template>
<script setup>

const props = defineProps({
    isOpen: Boolean,
    class: String,
    verticalAlign: String,
    overflow: String
})

const emit = defineEmits(['close']);

const close = () => {
    emit('close');
}

</script>