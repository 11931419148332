<template>
    <div :style="props.customZIndex ? `z-index: ${props.customZIndex}` : ''" :class="['fixed z-30 top-0 right-0 bottom-0 left-0 flex justify-center overflow-auto bg-overlay opacity-0 backdrop-blur-sm transition-all duration-300', { 'items-center' : !props.verticalAlign, 'opacity-100 lg:delay-300 pointer-events-auto': props.isVisible, 'lg:delay-0 pointer-events-none': !props.isVisible }, props.verticalAlign]" @click.self="emit('click')"><slot></slot></div>
</template>
<script setup>

const props = defineProps({
    isVisible: Boolean,
    customZIndex: Number,
    verticalAlign: String
});

const emit = defineEmits(['click']);

</script>