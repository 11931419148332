export const useLoader = () => {

  const loader = useState('loader', () => { return {}; });

  const show = (text: String) => {
    loader.value = {
      show: true,
      text: text
    };
  }

  const hide = () => {
    loader.value.show = false;
  }

  return {
    loader,
    show,
    hide
  }
};