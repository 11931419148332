<template>
    <img :src="props.src" :alt="props.alt" :title="props.title" :class="props.class" loading="lazy" />
</template>
<script setup>

const props = defineProps({
    src: String,
    alt: String,
    title: String,
    loading: String,
    class: String
});
</script>